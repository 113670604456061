import Vue from 'vue';
import VueRouter from 'vue-router';

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);


import HomePage from '../views/HomePage.vue';

const routes = [
  {
    path: "/",
    redirect: "/HomePage",
    meta: {
      name: '首页'
    },
  },
  {
    path: "/HomePage",
    name: "首页",
    component: HomePage,
  },
  {
    path: "/Notice",
    name: "通知公告列表",
    component: () => import("../views/Notice.vue"),
    meta: {
      title: "通知公告列表",
    },
  },
  {
    path: "/NoticeMsg",
    name: "通知公告内容",
    component: () => import("../views/NoticeMsg.vue"),
    meta: {
      title: "通知公告内容",
    },
  },
  {
    path: "/ScreenOut",
    name: "",
    component: () => import("../views/ScreenOut.vue"),
    meta: {
      title: "知识产权创新成果列表",
    },
  },
  {
    path: "/FileDetails",
    name: "知识产权创新成果列表",
    component: () => import("../views/FileDetails.vue"),
    meta: {
      title: "知识产权创新成果列表",
    },

  },
  {
    path: "/PersonalCenter",
    name: "个人中心",
    component: () => import("../views/PersonalCenter.vue"),
    meta: {
      title: "个人中心",
    },

  },
  {
    path: "/overtFile",
    name: "发布服务",
    component: () => import("../views/overtFile.vue"),
    meta: {
      title: "发布服务",
    },

  },
  {
    path: "/AboutUs",
    name: "关于我们",
    component: () => import("../views/AboutUs.vue"),
    meta: {
      title: "关于我们",
    },

  },
  {
    path: "/PatentConsultation",
    name: "专利、商标、版权咨询",
    component: () => import("../views/PatentConsultation.vue"),
    meta: {
      title: "专利、商标、版权咨询",
    },

  },
  {
    path: "/DevelopPage",
    name: "",
    component: () => import("../views/DevelopPage.vue"),
    meta: {
      title: "发展动态",
    },

  },
  {
    path: "/QualificaApply",
    name: "资质申请",
    component: () => import("../views/QualificaApply.vue"),
    meta: {
      title: "资质申请",
    },

  },
  {
    path: "/WhiteRights",
    name: "知识产权白名单",
    component: () => import("../views/WhiteRights.vue"),
    meta: {
      title: "知识产权白名单",
    },

  },
  {
    path: "/WhiteRightsLibrary",
    name: "知识产权项目储备库",
    component: () => import("../views/WhiteRightsLibrary.vue"),
    meta: {
      title: "知识产权项目储备库",
    },

  },
  {
    path: "/DevelopDetails",
    name: "专利服务中心详情",
    component: () => import("../views/DevelopDetails.vue"),
    meta: {
      title: "专利服务中心详情",
    },

  },
  {
    path: "/msgDetial",
    name: "msgDetial",
    component: () => import("../views/msgDetial.vue"),
    meta: {
      title: "消息详情",
    },

  },
  {
    path: "/searchPage",
    name: "searchPage",
    component: () => import("../views/searchPage.vue"),
    meta: {
      title: "搜索详情",
    },

  },
  {
    path: "/infoDetails",
    name: "infoDetails",
    component: () => import("../views/infoDetails.vue"),
    meta: {
      title: "资讯详情",
    },

  },
  {
    path: "/WhiteRightsLibraryDetails",
    name: "WhiteRightsLibraryDetails",
    component: () => import("../views/WhiteRightsLibraryDetails.vue"),
    meta: {
      title: "许可服务详情",
    },

  },










];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "active",
});

export default router
<template>
    <div class="body">
        <div class="topName">
            <div class="topBrief">
                <div class="imgBox" @click="openHomePage()">
                    <img :src="footList.pc_logo" width="126px" height="84px" alt="内蒙古巴彦绿业实业 (集团) 有限公司" srcset="" />
                </div>
                <div class="nameBrief">
                    <div>{{footList.pc_desc}}</div>
                    <div class="nameBriefMini">{{footList.pc_title}}</div>
                </div>
                <div class="searchBox">
                    <div class="searchBoxName">
                        <div @click="openOvert(4)">创新成果发布</div>
                        <div @click="openOvert(3)">产权融资发布</div>
                        <div @click="openOvert(2)">专利信息发布</div>
                    </div>
                    <div class="searchSlot">
                        <el-input placeholder="请输入关键字搜索" prefix-icon="el-icon-search" v-model="keyWord" size="mini"
                            @keyup.enter.native="openSearch()">
                            <template slot="append">
                                <div @click="openSearch()">搜索</div>
                            </template>
                        </el-input>
                    </div>
                </div>
            </div>
            <div class="loginBox">
                <div class="logined" v-if="!isLogin" @click="openLogin()">注册/登录</div>
                <div class="loginInfo" v-if="isLogin" @click="openPersonalCenter()">
                    <div class="infoImg">
                        <img :src="uesrDate.avatar" width="100%" height="" alt="" srcset="" />
                    </div>
                    <div class="infoName">
                        <div class="ellipsis" style="width: 100%;">
                            {{uesrDate.is_real==0?uesrDate.nickname:uesrDate.real_type==1?uesrDate.idname:uesrDate.enterpriseNameCh}}
                        </div>
                        <div class="infoTime">{{formatDate()}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="navigation">
            <el-menu :default-active="activeIndex" @select="chandActive" class="el-menu-demo" mode="horizontal">
                <el-menu-item index="1" @click="openMenu('/HomePage')">首页</el-menu-item>
                <el-submenu v-for="(items,i) in menuList" :key="i" :index="items.id">
                    <template slot="title">{{items.text}}</template>
                    <el-menu-item v-for="(item,j) in items.list" :key="j" :index="item.id" @click="openMenu(item.url)"
                        style="font-weight: 400;font-size: 16px;color: #333333;">{{item.title}}</el-menu-item>
                </el-submenu>
                <el-menu-item index="6" @click="openMenu('交易服务中心')">交易服务中心</el-menu-item>
                <el-menu-item index="7" @click="openMenu('/AboutUs')">关于我们</el-menu-item>

            </el-menu>
        </div>
        <el-dialog title="“天赋河套”农牧业产业和农业机械知识产权运营中心服务平台" :modal-append-to-body="false" :visible.sync="isTrue"
            width="700px" :before-close="handleClose">
            <div class="registerBox">
                <div>
                    <img :src="footList.pc_logo" width="340px" alt="" srcset="">
                </div>
                <div class="registerFromBox">
                    <div class="registerFromTitle" v-if="loginSatus=='1' || loginSatus=='2'">
                        {{loginSatus=='1'?'用户注册':'找回密码'}}</div>
                    <div class="registerFromTitleWay" v-if="loginSatus=='3' || loginSatus=='4'">
                        <span :class="{'wayColor':loginSatus=='3'}" @click="changeWay(3)">账户密码登录</span>
                        <span :class="{'wayColor':loginSatus=='4'}" @click="changeWay(4)">手机号登录</span>
                    </div>
                    <div>

                        <el-form ref="form" :rules="rules" :model="form" label-width="0px">
                            <el-form-item label="" prop="mobile">
                                <el-input size="medium" placeholder="请输入手机号" v-model="form.mobile">
                                    <template slot="prepend"><i class="el-icon-mobile"></i></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="" v-if="loginSatus!='3'" prop="code">
                                <el-input size="medium" placeholder="请输入短信验证码" v-model="form.code">
                                    <el-button slot="append" class="submitTimeBtn"
                                        @click="onSubmit()">{{!showTime?'获取验证码':clockTime+'秒'}}</el-button>

                                </el-input>
                            </el-form-item>
                            <el-form-item label="" v-if="loginSatus!='4'" prop="pass">
                                <el-input size="medium" type="password" placeholder="请输入密码" v-model="form.pass">
                                    <template slot="prepend"><i class="el-icon-lock"></i></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="" v-if="loginSatus=='1' || loginSatus=='2'" prop="pass">
                                <el-input size="medium" type="password" placeholder="请再次输入密码" v-model="form.password">
                                    <template slot="prepend"><i class="el-icon-lock"></i></template>
                                </el-input>
                            </el-form-item>
                            <el-form-item>
                                <div class="submitBtn">
                                    <el-button
                                        @click="onSubmitRegister()">{{loginSatus=='1'?'提交注册':loginSatus=='2'?'提交':'登录'}}</el-button>
                                </div>


                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="radioBox" v-if="loginSatus=='1'">
                        <el-radio v-model="radio" label="1">我已同意<span @click="openProtocol('注册协议')">《注册协议》</span><span
                                @click="openProtocol('隐私协议')">《隐私政策》</span></el-radio>
                    </div>
                    <div class="switchRadio" v-if="loginSatus=='1' ">已有账号？
                        <span @click="changeWay(3)">前往登录</span>
                    </div>
                    <div class="btnBox" v-if="loginSatus=='3'|| loginSatus=='4'">
                        <div @click="changeWay(1)">用户注册</div>
                        <div class="retrievePass" @click="changeWay(2)">
                            找回密码?
                        </div>
                    </div>

                </div>


            </div>
            <!-- <div slot="footer" class="dialog-footer">
                <el-button @click="isTrue = false">取 消</el-button>
                <el-button type="primary" @click="isTrue = false">确 定</el-button>
            </div> -->
        </el-dialog>
        <el-dialog :title="protocolText" :modal-append-to-body="false" :visible.sync="protocolDialog" width="640px"
            :before-close="handleClosemini">
            <div v-html="protocolTttle">
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {
        sendRegSms,
        sendLoginSms,
        sendForgetSms,
        loginSms,
        updatePassByMobile,
        doReg,
        loginPass,
        protocol,
        menuCate,
        infoConfig
    } from '../api/loginApi.js'
    import { personalInfo } from '../api/personalCenterApi.js'
    import CryptoJS from 'crypto-js'
    // import { mapState, mapActions, mapMutations } from "vuex";
    export default {
        name: 'HeadTop',
        // computed: {
        //     ...mapState('login', ['isLogin'])
        // },
        data() {
            return {
                isLogin: false, // 是否登录
                keyWord: '',
                dateTime: '',
                protocolText: '',
                protocolTttle: '',
                protocolDialog: false,
                menuList: [
                    {
                        id: '2',
                        text: '专利服务中心',
                        list: [
                            {
                                id: '2-1',
                                title: '技术研发信息',
                                url: '/DevelopPage?val=技术研发信息'
                            },
                            {
                                id: '2-2',
                                title: '发展动态',
                                url: '/DevelopPage?val=发展动态'
                            },
                            {
                                id: '2-3',
                                title: '专利服务信息',
                                url: '/DevelopPage?val=专利服务信息'
                            },
                        ]
                    },
                    {
                        id: '3',
                        text: '资产托管中心',
                        list: [
                            {
                                id: '3-1',
                                title: '专利、商标、版权咨询',
                                url: '/QualificaApply'
                            },
                            {
                                id: '3-2',
                                title: '资质申请',
                                url: '/PatentConsultation'
                            },
                            {
                                id: '3-3',
                                title: '转让许可服务',
                                url: '/WhiteRightsLibrary?val=转让许可服务'
                            },
                            {
                                id: '3-4',
                                title: '质押金融',
                                url: '质押金融'
                            },
                        ]
                    },
                    {
                        id: '4',
                        text: '金融服务中心',
                        list: [
                            {
                                id: '4-1',
                                title: '知识产权融资',
                                url: '知识产权融资'
                            },
                            {
                                id: '4-2',
                                title: '知识产权白名单',
                                url: '/WhiteRights'
                            },
                            {
                                id: '4-3',
                                title: '知识产权项目储备库',
                                url: '/WhiteRightsLibrary'
                            },
                        ]
                    },
                    {
                        id: '5',
                        text: '投资运营中心',
                        list: [
                            {
                                id: '5-1',
                                title: '知识产权专利池',
                                url: '知识产权专利池'
                            },
                            {
                                id: '5-2',
                                title: '知识产权创新成果',
                                url: '知识产权创新成果'
                            },
                        ]
                    },

                ],
                activeIndex: '1',
                isTrue: false,
                form: {
                    mobile: '',
                    code: '',
                    pass: '',
                    password: '',
                    invite_code: '',
                },
                rules: {
                    mobile: [
                        { required: true, message: "手机号不能为空", trigger: "change" },
                        { message: "请正确输入手机号", pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, trigger: "change" },
                    ],
                    code: [
                        { required: true, message: "验证码不能为空", trigger: "change" },
                    ],
                    pass: [
                        { required: true, message: "密码不能为空", trigger: "change" },
                        { message: "密码不得小于6位", pattern: /^[0-9A-Za-z]{6,}$/, trigger: "change" },
                    ]

                },
                showTime: false,
                clockTime: 60,
                radio: '',
                loginSatus: 3,     // 1 注册 2找回密码 3密码登录 4手机号登录
                footList: {},
                uesrDate: {}
            };

        },
        watch: {
            getToken() {
                console.log(111, ';pp')

            },
        },
        created() {
            if (sessionStorage.getItem("token")) {
                this.isLogin = true;
            }
            this.getLink();
            this.$bus.$off('changeLogin')
            this.$bus.$on('changeLogin', () => {
                this.openLogin();
            });
            this.$bus.$off('changeName')
            this.$bus.$on('changeName', () => {
                this.getInfo();
            });
            this.$bus.$off('changNotLogin')
            this.$bus.$on('changNotLogin', () => {
                sessionStorage.setItem('isLogin', false);
                this.isLogin = false;
                this.uesrDate = {};
            });
        },
        methods: {
            // ...mapActions('login', ['changeIsloginAction']),
            // 点击logo回到首页
            openHomePage() {
                this.$router.push({
                    path: '/',
                    query: {}
                })
            },
            // 获取友情链接
            getLink() {
                infoConfig().then(res => {
                    if (res.code == 200) {
                        this.footList = res.data;
                        if (sessionStorage.getItem("token")) {
                            this.getInfo();
                        }
                    } else {
                        // this.msgError("获取友情链接失败")
                    }
                })
            },
            // 获取用户数据
            getInfo() {
                personalInfo().then(res => {
                    if (res.code == 200) {
                        const key = CryptoJS.enc.Utf8.parse(sessionStorage.getItem('apiSalt'));
                        const result = CryptoJS.AES.decrypt(res.data, key, { iv: key, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString(CryptoJS.enc.Utf8);
                        this.uesrDate = JSON.parse(result);
                        sessionStorage.setItem('realType', this.uesrDate.is_real)
                        // console.log(this.uesrDate, 'this.uesrDate')
                    } else {
                        // this.msgError('加载个人信息失败')
                    }
                })
            },
            handleClosemini(done) {
                done();
            },
            // 关闭立即联系
            handleClose(done) {
                done();
            },
            // 获取首页top栏
            getMune() {
                menuCate().then(res => {
                    if (res.code == 200) {
                        this
                    } else {
                        this.msgError(res.msg)
                    }
                })
            },
            // 发送验证码
            onSubmit() {
                let that = this
                clearInterval();
                if (that.form.mobile) {
                    if (that.loginSatus == 1) {
                        sendRegSms({ mobile: that.form.mobile }).then(res => {
                            if (res.code == 200) {
                                this.msgSuccess(res.msg);
                                that.clockTime = 60;
                                that.showTime = true;
                                setInterval(() => {
                                    that.clockTime -= 1;
                                    if (that.clockTime == 0) {
                                        clearInterval();
                                        this.showTime = false;
                                    }
                                }, 1000)
                            } else {
                                this.msgError(res.msg)
                            }
                        })


                    } else if (that.loginSatus == 2) {
                        sendForgetSms({ mobile: that.form.mobile }).then(res => {
                            if (res.code == 200) {
                                this.msgSuccess(res.msg);
                                that.clockTime = 60;
                                that.showTime = true;
                                setInterval(() => {
                                    that.clockTime -= 1;
                                    if (that.clockTime == 0) {
                                        clearInterval();
                                        this.showTime = false;
                                    }
                                }, 1000)
                            } else {
                                this.msgError(res.msg)
                            }
                        })
                    } else {
                        sendLoginSms({ mobile: that.form.mobile }).then(res => {
                            if (res.code == 200) {
                                this.msgSuccess(res.msg);
                                that.clockTime = 60;
                                that.showTime = true;
                                setInterval(() => {
                                    that.clockTime -= 1;
                                    if (that.clockTime == 0) {
                                        clearInterval();
                                        this.showTime = false;
                                    }
                                }, 1000)
                            } else {
                                this.msgError(res.msg)
                            }
                        })
                    }
                } else {
                    this.msgError("请先输入手机号码")
                }
            },
            // 提交注册、提交忘记密码、提交登录
            onSubmitRegister() {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        if (this.loginSatus == 1) {
                            if (this.radio) {
                                if (this.form.pass == this.form.password) {
                                    doReg(this.form).then(res => {
                                        if (res.code == 200) {
                                            this.form = {};
                                            this.resetForm("form");
                                            this.loginSatus = 3;
                                            this.msgSuccess(res.msg);
                                        } else {
                                            this.msgError(res.msg)
                                        }
                                    })
                                } else {
                                    this.msgError("再次输入密码与密码不符")
                                }

                            } else {
                                this.msgError('请阅读注册协议和隐私政策之后勾选我已同意！')
                            }

                        } else if (this.loginSatus == 2) {
                            updatePassByMobile(this.form).then(res => {
                                if (res.code == 200) {
                                    this.msgSuccess("修改成功请重新登录");
                                    this.loginSatus = 3;
                                    this.form = {};
                                    this.resetForm('form');
                                } else {
                                    this.msgError(res.msg)
                                }
                            })
                        } else if (this.loginSatus == 3) {
                            loginPass(this.form).then(res => {
                                if (res.code == 200) {
                                    // this.isLogin = true;
                                    this.msgSuccess(res.msg);
                                    this.reportToken(res.data);
                                    this.clearFrom();
                                    this.handleClose();
                                    this.getInfo();
                                } else {
                                    this.msgError(res.msg)
                                }
                            })
                        } else {
                            loginSms(this.form).then(res => {
                                if (res.code == 200) {
                                    // this.isLogin = true;
                                    this.msgSuccess(res.msg);
                                    this.reportToken(res.data);
                                    this.clearFrom();
                                    this.handleClose();
                                    this.getInfo();
                                } else {
                                    this.msgError(res.msg)
                                }
                            })
                        }
                    }
                })
            },
            // 缓存用户数据和token
            reportToken(date) {
                sessionStorage.setItem('token', date.token);
                sessionStorage.setItem('uid', date.uid);
                sessionStorage.setItem('mobile', date.mobile);
                sessionStorage.setItem('apiSalt', date.api_salt);
                sessionStorage.setItem('isLogin', true);
                this.isLogin = true;
                // this.changeIsloginAction(this.isLogin);
            },
            // 切换当前登录方式
            changeWay(way) {
                this.loginSatus = way;
                this.form = {};
                this.resetForm("form");
                clearInterval();
                this.showTime = false;
                this.clockTime = 60;
            },
            // 关闭注册登录弹窗
            handleClose() {
                this.loginSatus = 3;
                this.isTrue = false;
                // this.$confirm('确认关闭？')
                //     .then(() => {

                //     })
                //     .catch(() => { });
            },
            // 打开注册协议和隐私协议
            openProtocol(name) {
                protocol({ id: name == '注册协议' ? 6 : 10 }).then(res => {
                    if (res.code == 200) {
                        this.protocolText = name;
                        this.protocolTttle = res.data.value
                        this.protocolDialog = true;
                    } else {
                        this.msgError(res.msg)
                    }
                })
            },
            // 跳转
            openMenu(e) {
                // window.location.reload();
                console.log(this.$route.matched, 'pppp')
                console.log(e, 'llllllll')

                if (e == '交易服务中心') {
                    this.$router.push({
                        path: '/ScreenOut',
                        query: {
                            type: '首页',
                            val: '资深顾问团队'
                        },
                    })
                } else if (e == '知识产权融资') {
                    this.$router.push({
                        path: '/ScreenOut',
                        query: {
                            type: '首页',
                            val: '知识产权融资'
                        }
                    })
                } else if (e == '知识产权专利池') {
                    this.$router.push({
                        path: '/ScreenOut',
                        query: {
                            type: '首页',
                            val: '专利服务'
                        }
                    })
                }
                else if (e == '知识产权创新成果') {
                    this.$router.push({
                        path: '/ScreenOut',
                        query: {
                            type: '首页',
                            val: '知识产权创新成果'
                        }
                    })
                }

                else if (e == '质押金融') {
                    window.open('https://nmgcqjy.ejy365.com/');
                } else {
                    this.$router.push({
                        path: e,
                        query: {}
                    })
                }

            },
            // 跳转发布页面
            openOvert(id) {
                if (sessionStorage.getItem('token')) {
                    if (sessionStorage.getItem('realType') != 0) {
                        this.$router.push({
                            path: '/overtFile',
                            query: {
                                type: id
                            }
                        })
                    } else {
                        this.msgError("亲爱的天赋河套用户，您需要在个人中心-基本信息-实名认证成功后才可以发布")
                        this.$router.push({
                            path: '/PersonalCenter',
                            query: {}
                        })
                    }
                } else {
                    this.msgError("亲爱的天赋河套用户，请先登录");
                    this.openLogin();
                }

                // window.location.reload();
            },
            chandActive(e) {
                this.activeIndex = e;
            },
            // 跳转搜索页面
            openSearch() {
                if (this.keyWord != '') {

                    console.log(777)
                    this.$router.push({
                        path: '/searchPage',
                        query: {
                            val: this.keyWord
                        }
                    })
                    this.$bus.$emit('changeSearch', { keyWord: this.keyWord });
                    // this.keyWord='';
                } else {
                    this.msgError("搜索内容不能为空！")
                }

            },
            // 打开注册/登录弹窗
            openLogin() {
                this.isTrue = true;
                this.showTime = false;
                this.clearFrom();
            },
            // 清除弹窗的数据
            clearFrom() {
                this.loginSatus = 3;
                this.form = {};
                this.resetForm('form');
            },
            // 打开个人中心
            openPersonalCenter() {
                this.activeIndex = '';
                this.$router.push(
                    {
                        path: '/PersonalCenter',
                        query: {}
                    }
                )
            }
        }


    }
</script>

<style scoped>
    .body {
        width: 100%;
        height: 180px;
        /* padding: 18px 238px; */
        background: #FFFFFF;
    }

    .topName {
        width: 1350px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0px 40px;
    }

    .topBrief {
        width: 1055px;
        display: flex;
        align-items: center;
    }

    .imgBox {
        width: 126px;
        height: 84px;
    }

    .nameBrief {
        width: 655px;
        height: 84px;
        font-weight: 500;
        font-size: 22px;
        color: #333333;
        line-height: 40px;
        padding-left: 10px;
    }

    .nameBriefMini {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 16px;
        margin-top: 14px;
    }

    .searchBox {
        width: 320px;
        padding-top: 38px;
        margin-left: 20px;
    }

    .searchBoxName {
        min-width: 80px;
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 16px;
        color: #333333;
        line-height: 40px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        cursor: pointer;
        cursor: hand;
    }

    .searchSlot {
        width: 100%;
        height: 72px;
        margin-top: 10px;
    }

    .searchSlot .el-input-group,
    .searchSlot .el-input__inner {
        border: 1px solid #00A166;
        border-radius: 50px;
    }

    .searchSlot .el-input ::v-deep input {
        border: none;
        border-radius: 50px;
    }

    .searchSlot .el-input ::v-deep .el-input-group__append {
        background-color: #00A166;
        color: white;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        border-radius: 50px;
    }

    ::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
        border-bottom: 2px solid white;
    }

    .loginBox {
        width: 180px;
        height: 120px;
        cursor: pointer;
        cursor: hand;
    }

    .logined {
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 120px;
        text-align: right;
    }

    .logined:hover {
        color: #1275BE;
        text-decoration: underline;
    }

    .loginInfo {
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;

    }

    .infoImg,
    .infoImg img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }

    .infoName {
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 24px;
        margin-left: 10px;
    }

    .infoTime {
        font-weight: 400;
        font-size: 12px;
        color: #999999;
    }

    .navigation {
        width: 100%;
        height: 60px;
        background: #00A166;

    }

    .navigation ::v-deep .el-menu-demo {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        padding: 0px 40px;
        /* justify-content: space-between; */
        background: #00A166;
    }

    .navigation ::v-deep .el-menu-item {
        width: 15%;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
    }

    .navigation ::v-deep .el-menu--horizontal>.el-menu-item.is-active {
        background: #FFFFFF;
        color: #00A166;
        border: 1px solid #00A166;
    }

    .navigation ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
        color: #00A166;
    }

    .navigation ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
        color: #00A166;
    }

    .navigation ::v-deep .el-submenu {
        width: 15%;
    }

    .navigation ::v-deep .el-submenu__title {

        text-align: center;
        font-weight: 500;
        font-size: 18px;
    }

    .navigation ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title {
        color: #FFFFFF;
    }

    .navigation ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
        color: #00A166;
    }


    /* 注册登录弹窗 */
    .registerBox {
        width: 100%;
        display: flex;
        align-items: center;
    cursor: pointer;
    cursor: hand;
        /* justify-content: space-between; */

    }

    .registerFromBox {
        width: 300px;
        text-align: center;
        margin-left: 20px;

    }

    .registerFromTitle {
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        margin-bottom: 28px;
    }

    .registerFromTitleWay {
        font-weight: 400;
        font-size: 20px;
        color: #999999;
        margin-bottom: 28px;
        display: flex;
        justify-content: space-around;
    }

    .wayColor {
        font-weight: 500;
        font-size: 20px;
        color: #00A166;
    }

    .submitBtn ::v-deep .el-button {
        width: 100%;
        height: 52px;
        font-size: 16px;
        background-color: #00A166;
        color: #fff;
    }

    .radioBox {
        font-weight: 400;
        font-size: 13px;
        color: #666666;
    }

    .radioBox span {
        font-weight: 400;
        font-size: 13px;
        color: #00A166;
    }

    .switchRadio {
        font-weight: 400;
        font-size: 13px;
        color: #666666;
        margin: 18px 0;
    }

    .switchRadio span {
        color: #00A166;
    }

    .retrievePass {
        font-weight: 400;
        font-size: 13px;
        color: #00A166;
        line-height: 15px;
        text-align: right;
    }

    .submitTimeBtn {
        width: 100px;

    }

    .btnBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: 13px;
        color: #00A166;
    }
</style>
<template>
    <div class="body">
        <div class="footBox">
            <div class="foot">
                <div>
                    <div class="footTitle">友情链接</div>
                    <div>
                        <div class="footText coumerFoot" v-for="(items,index) in footList.link" :key="index"
                            @click="openLink(items.link)">{{items.name}}</div>
                    </div>

                </div>
                <div>
                    <div class="footTitle">联系我们</div>
                    <div>
                        <div class="footText">
                            <span>联系电话：</span>
                            <span>{{footList.pc_mobile}}</span>
                        </div>
                        <div class="footText">
                            <span>联系邮箱：</span>
                            <span>{{footList.pc_email}}</span>
                        </div>
                        <div class="footText">
                            <span>联系地址：</span>
                            <span>{{footList.pc_img_center0}}</span>
                        </div>

                    </div>

                </div>
                <div class="footCode">
                    <div class="footTitle">扫一扫</div>
                    <div>
                        <div class="footText">关注我们</div>
                        <div>
                            <img :src="footList.official_account" width="140px" height="140px" alt="" srcset="">
                        </div>
                        <div class="footText">官方公众号</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footTag" @click="openCopyright()">
            copyright@{{formatDate2('',1)}} 内蒙古天赋策略咨询有限公司 保所有权利 蒙ICP备2023002804号-1 号 单位门户网站 法律声明
        </div>
    </div>
</template>
<script>
    import {
        infoConfig
    } from '../api/loginApi.js'
    export default {
        name: 'FootBox',
        data() {
            return {
                footList: {}
            }
        },
        created() {
            this.getLink();
        },
        methods: {
            // 获取友情链接
            getLink() {
                infoConfig().then(res => {
                    if (res.code == 200) {
                        this.footList = res.data;
                    } else {
                        // this.msgError("获取友情链接失败")
                    }
                })
            },
            // 打开新的链接
            openLink(url) {
                console.log(url)
                window.open(url);
            },
            // 打开备案链接
            openCopyright() {
                window.open('https://beian.miit.gov.cn/');
            },
        }
    }
</script>
<style scoped>
    .body {
        width: 100%;
    }

    .footBox {
        width: 100%;
        background: #00A166;
    }

    .foot {
        width: 1200px;
        margin: 0 auto;
        padding: 40px;
        display: flex;
        justify-content: space-between;
        color: #FFFFFF;

    }

    .footTitle {
        font-weight: 800;
        font-size: 18px;
        margin-bottom: 32px;
    }

    .footText {
        font-weight: 500;
        font-size: 12px;
        margin: 15px 0;
    }
    .coumerFoot{
        
        cursor: pointer;
        cursor: hand;
    }

    .footCode {
        width: 140px;
        text-align: center;
    }

    .footTag {
        width: 100%;
        height: 58px;
        text-align: center;
        line-height: 58px;
        font-weight: 500;
        font-size: 12px;
        color: #333333;
        background: #FFFFFF;
    }
</style>
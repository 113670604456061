<template>
    <div>
        <div>
            <div v-if="orderType!='资深顾问团队'">
                <img :src="resultDate.cover_img?resultDate.cover_img:resultDate.cover?resultDate.cover:resultDate.imgs"
                    width="100%" height="166px" style="border-radius:8px" alt="" srcset="" @click="open()">
            </div>
            <div class="innovateBoxText ellipsis" v-if="title!='资深顾问团队'">
                {{resultDate.name}}
            </div>
            <div class="character" v-if="orderType=='资深顾问团队'">
                <div class="characterBox">
                    <div class="characterBox1">
                        <img :src="resultDate.avatar" width="100%" height="100%" style="border-radius:50%" alt=""
                            srcset="" @click="open()">
                    </div>

                </div>
                <div class="characterName">
                    {{resultDate.name}}
                </div>
                <div class="characterText ellipsis">
                    {{resultDate.desc}}
                </div>
            </div>
            <div class="tagBox" v-if="resultDate.cateList && resultDate.cateList.length>0">
                <span v-for="(items,j) in resultDate.cateList" :key="j" class="spanbox">
                    {{items}}
                </span>
            </div>
            <div class="tagBox" v-else>
                <span v-for="(items,j) in resultDate.labelList" :key="j" class="spanbox">
                    {{items}}
                </span>
            </div>
            <div class="gainInfo" v-if="orderType!='知识产权融资' && orderType!='资深顾问团队' ">
                <span style="display: inline-block;width: 90px;">专利拥有人</span>
                <span class="gainInfoTetx">{{resultDate.idname}}</span>
            </div>
            <div class="gainPhone">
                <div style="display: flex;align-items:center;" @click="openDialog()">
                    <i class="el-icon-phone-outline " style="color:#00A166;font-size: 16px;margin-right: 10px;"></i>
                    立即联系
                </div>
                <div style="display: flex;align-items:center;" @click="open()">
                    查看详情
                    <div class="miniTitleIcon"><i class="el-icon-arrow-right"></i></div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogLince" :modal-append-to-body="false" class="dialogBox" width="640px" :before-close="handleClose">
            <template slot="title">
                <div class="topText">
                    立即联系
                </div>
            </template>
            <div>
                <div>
                    <el-form :rules="rules" label-position="top" label-width="100px" ref="from" :model="from">
                        <el-row>
                            <el-col :span="11" style="margin-right: 20px;">
                                <el-form-item label="" prop="name">
                                    <template slot="label">
                                        <span class="formText">
                                            姓名
                                        </span>
                                    </template>
                                    <el-input size="medium" v-model="from.name" placeholder="请输入您的姓名"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="11">
                                <el-form-item label="" prop="link_mobile">
                                    <template slot="label">
                                        <span class="formText">
                                            联系方式
                                        </span>
                                    </template>
                                    <el-input size="medium" v-model="from.link_mobile"
                                        placeholder="请输入您的手机号码"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-form-item label="" prop="detail">
                                <template slot="label">
                                    <span class="formText">
                                        联系事项
                                    </span>
                                </template>
                                <el-input size="medium" type="textarea" maxlength="500" rows="6" show-word-limit
                                    v-model="from.detail" placeholder="请简单描述联系事项"></el-input>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-checkbox v-model="checked">我已阅读并同意<span
                                    @click="openProtocol('隐私协议')">《隐私保护声明》</span></el-checkbox>
                        </el-row>
                    </el-form>

                </div>
                <div>
                    <div class="btnBox">
                        <div class="submitBtn" @click="onSubmit">
                            立即提交
                        </div>
                    </div>
                    <div class="btnBoxText">
                        <span>注意：</span>
                        请如实填写信息，提交后我们将在48小时内与您取得联系
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog :title="protocolText" :modal-append-to-body="false" :visible.sync="protocolDialog" width="640px" :before-close="handleClosemini">
            <div v-html="protocolTttle">
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import {
        addContact
    } from '../api/cardBoxApi.js'
    import {
        protocol
    } from '../api/loginApi.js'
    export default {
        name: 'CardBox',
        props: {
            resultDate: Object,
            title: String,
            text: String
        },
        watch: {
            $route() {

            }
        },

        data() {
            return {
                orderType: this.title,
                dialogLince: false,
                checked: false,
                from: {},
                rules: {
                    name: [
                        { required: true, message: "姓名不能为空", trigger: "change" },
                    ],
                    link_mobile: [
                        { required: true, message: "联系方式不能为空", trigger: "change" },
                        { message: "请正确输入手机号", pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, trigger: "change" },
                    ],
                    detail: [
                        { required: true, message: "联系事项不能为空", trigger: "change" },
                    ],
                },
                protocolText: '',
                protocolDialog: false,
                protocolTttle: ''
            };

        },
        created() {


        },
        methods: {
            handleClosemini(done) {
                done();
            },
            // 关闭立即联系
            handleClose(done) {
                done();
            },
            // 打开查看更多
            open() {
                // console.log(this.$route.path,'44')
                // if (this.$route.path=='/FileDetails') {
                // this.$emit('changeDate',{id:this.resultDate.id})
                // this.$router.push({
                //     path: '/FileDetails',
                //     query: {
                //         val: this.orderType,
                //         id: this.resultDate.id,
                //         text: this.text,
                //     }
                // })
                // }else{
                console.log(this.text,'777')
                if (this.text == '转让许可服务') {
                    this.$router.push({
                        path: '/WhiteRightsLibraryDetails',
                        query: {
                            val: this.orderType,
                            id: this.resultDate.id,
                            typeId:this.resultDate.typeid
                        }
                    })
                }else if (this.orderType == '资讯') {
                    this.$router.push({
                        path: '/infoDetails',
                        query: {
                            val: this.orderType,
                            id: this.resultDate.id,
                            typeId:this.resultDate.typeid
                        }
                    })
                }else {
                    this.$router.push({
                        path: '/FileDetails',
                        query: {
                            val: this.orderType,
                            id: this.resultDate.id,
                            text: this.text,
                        }
                    })
                }
                // }

            },
            // 打开立即联系
            openDialog() {
                console.log(this.getToken,'55')
                if (sessionStorage.getItem("token")) {
                    this.dialogLince = true;
                    this.resetForm("from");
                    this.from = { link_id: this.resultDate.id };
                } else {
                    this.$bus.$emit('changeLogin');
                }

            },
            // // 关闭
            // handleClose(){
            //     this.protocolDialog=false;
            // },
            // 打开注册协议和隐私协议
            openProtocol(name) {
                protocol({ id: name == '注册协议' ? 6 : 10 }).then(res => {
                    if (res.code == 200) {
                        this.protocolText = name;
                        this.protocolTttle = res.data.value
                        this.protocolDialog = true;
                    } else {
                        this.msgError(res.msg)
                    }
                })
            },
            // 立即联系-立即提交
            onSubmit() {
                console.log(this.checked, 'this.checked')
                this.from.typeid = this.title == '知识产权创新成果' ? 1 : this.title == '知识产权融资' ? 2 : this.title == '专利服务' ? 3 : this.title == '资深顾问团队' ? 4 : 5;
                if (this.checked) {
                    this.$refs["from"].validate((valid) => {
                        if (valid) {
                            addContact(this.from).then(res => {
                                if (res.code == 200) {
                                    this.msgSuccess("提交联系成功");
                                    this.dialogLince = false;
                                } else {
                                    this.msgError('提交失败')
                                }
                            })
                        }
                    })
                } else {
                    this.msgError('请先勾选我同意')
                }

            }
        }


    }

</script>
<style scoped>
    .innovateBox {
        width: 100%;
        display: flex;
        /* justify-content: space-between; */
        margin-top: 24px;
        flex-wrap: wrap;
    }
    .innovateBoxText {
        width: 100%;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        line-height: 23px;
        padding: 12px 0;
    }

    .tagBox {
        width: 100%;
        height: 34px;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
    }
    .tagBox span{
        display: inline-block;
    }

    .spanbox {
        background: #F4F4F4;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        color: #999999;
        line-height: 14px;
        padding: 10px;
        margin-right: 4px;
        margin-bottom: 4px;
    }

    .gainInfo {
        width: 100%;
        /* height: 40px; */
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 16px;
        margin-top: 12px;
    }

    .gainInfoTetx {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 16px;
        margin-left: 8px;
    }

    .gainPhone {
        font-weight: 400;
        font-size: 14px;
        color: #00A166;
        line-height: 16px;
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
    }

    .character {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .characterName {
        font-weight: 500;
        font-size: 32px;
        color: #333333;
        line-height: 38px;
        margin-top: 10px;
        margin-bottom: 6px;
    }

    .characterText {
        width: 100%;
        height: 32px;
        /* overflow: hidden; */
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        line-height: 19px;
    }

    .topText {
        width: 100%;
        font-weight: 500;
        font-size: 28px;
        color: #00A166;
        text-align: center;
    }

    .formText {
        font-weight: 500;
        font-size: 20px;
        color: #333333;
    }

    .el-checkbox__label {
        font-weight: 400;
        font-size: 12px;
        color: #333333;
        line-height: 14px;
    }

    .el-checkbox__label span {
        font-weight: 400;
        font-size: 12px;
        color: #00A166;
        line-height: 14px;
    }

    .btnBox {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }

    .submitBtn {
        width: 131px;
        height: 42px;
        background: #00A166;
        border-radius: 4px;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        line-height: 42px;
    }

    .serviceBtn {
        width: 131px;
        height: 42px;
        border-radius: 4px;
        border: 1px solid #00A166;
        font-weight: 400;
        font-size: 16px;
        color: #00A166;
        text-align: center;
        line-height: 42px;
        margin-left: 20px;
    }

    .btnBoxText {
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        line-height: 14px;
    }

    .btnBoxText span {
        font-weight: 400;
        font-size: 12px;
        color: #FF7A00;
        line-height: 14px;
    }

    .characterBox {
        position: relative;
        width: 100%;
        /* 或者指定具体的宽度 */
        padding-bottom: 100%;
        /* 16:9的宽高比 = (9 / 16 * 100%) */
    }

    .characterBox1 {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
</style>
<style>
    .dialogBox .el-dialog {
        background-image: url('../assets/bgImg.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 12px;
    }
</style>
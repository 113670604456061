<template>
  <div id="app" ref="app">
    <HeadTop class="boxTop" />
    <router-view v-if="showRouterView" style="margin-top: 180px;" />
    <FootBox />
  </div>
</template>

<script>
  import HeadTop from './components/HeadTop.vue';
  import FootBox from './components/FootBox.vue';
  import drawMixin from "./drawMixin.js";
  import _ from 'lodash'
  export default {
    mixins: [drawMixin],
    name: 'App',
    components: {
      HeadTop,
      FootBox
    },
    data() {
      return {
        showRouterView: true,
      };

    },
    created() {

    },
    mounted() {


      // this.$nextTick(() => {
      //   const $app = this.$refs.app;
      //   // 设置 屏幕 百分比 尺寸 适配
      //   const standardScale = "100%" / "100%";

      //   window.addEventListener(
      //     "resize",
      //     _.debounce(function () {
      //       const docHeight = document.body.clientHeight;
      //       const docWidth = document.body.clientWidth;
      //       // console.log(docHeight, docWidth, 55)
      //       if (docWidth < 1680) {
      //         const currentScale = docHeight / docWidth;
      //         let [scale, translate] = [0, 0];
      //         if (currentScale < standardScale) {
      //           //   // 以高度计算
      //           scale = docHeight / 1080;
      //           const shouleWidth = 1920 * scale;
      //           const offsetWidth = docWidth - shouleWidth;
      //           translate = offsetWidth > 0 ? `translate(0px, 0)` : "";
      //           // ${offsetWidth / 2}
      //           //   // 
      //         } else {
      //           // 以宽度计算
      //           scale = docWidth / 1920;
      //           const shouleHeight = 1080 * scale;
      //           const offsetHeight = docHeight - shouleHeight;
      //           translate =
      //             offsetHeight > 0 ? `translate(0, 0px)` : "";
      //         }
      //         // console.log(scale, shouleHeight, offsetHeight, translate);
      //         $app.style.cssText = `
      //       transform: scale(${scale}) ${translate};
      //       transform-origin: top left;
      //       min-width: 1920px;
      //       min-height: 1080px;
      //     `;


      //       } else {
      //         $app.style.cssText = "";
      //       }
      //     }),
      //     0
      //   );

      // if (document.createEvent) {
      //   console.log(document.createEvent, 'd,ocument.createEvent)', document.createEvent("HTMLEvents"))
      //   var event = document.createEvent("HTMLEvents");
      //   event.initEvent("resize", true, true);
      //   window.dispatchEvent(event);
      // } else if (document.createEventObject) {
      //   window.fireEvent("onresize");
      // }
      // });
    },
    methods: {

    }
  }
</script>

<style>
  #app {
    z-index: 3;
    position: absolute;
    /* width: 1920px;
    height: 1080px; */
    width: 100%;
    top: 0%;
    left: 0%;
    transform-origin: left top;
    margin: 0;
    padding: 0;

  }

  .widthBox {
    width: 1120px;
    margin: 0 auto;
  }

  /* #app {
    width: 100%;
    height: 100%;
    background: #F5F5F5;
    margin: 0px;
    padding: 0px; */
  /* } */
  .boxTop {
    width: 100%;
    height: 180px;
    position: fixed;
    z-index: 666;
    top: 0;
    left: 0;
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ellipsis-multiline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .innovateBox {
    width: 1120px;
    display: flex;
    /* justify-content: space-between; */
    margin: 60px auto 0 auto;
    flex-wrap: wrap;
  }

  .innovateOneBox {
    width: 24%;
    /* height: 390px; */
    background: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 24px;
    padding: 16px;
    box-sizing: border-box;
    cursor: pointer;
    cursor: hand;
    /* margin-right: 13px; */
  }

  .innovateBox :nth-child(2) {
    margin-left: 1.3%;
    margin-right: 1.3%;
  }

  .innovateBox :nth-child(3) {
    margin-right: 1.3%;
  }

  .innovateBox :nth-child(4n+2) {
    margin-left: 1.3%;
    margin-right: 1.3%;
  }

  .innovateBox :nth-child(4n+3) {
    margin-right: 1.3%;
  }
</style>
// 登陆注册验证码
import axios from "../utils/request";

// 发送注册验证码
export const sendRegSms = (date) =>
    axios({
        url: "/login/sendRegSms",
        method: "POST",
        data: date,
    });

// 发送登录验证码
export const sendLoginSms = (date) =>
    axios({
        url: "/login/sendLoginSms",
        method: "POST",
        data: date,
    });



// 发送忘记密码验证码
export const sendForgetSms = (date) =>
    axios({
        url: "/login/sendForgetSms",
        method: "POST",
        data: date,
    });

// 注册
export const doReg = (date) =>
    axios({
        url: "/login/doReg",
        method: "POST",
        data: date,
    });

// 验证码登录
export const loginSms = (date) =>
    axios({
        url: "/login/loginSms",
        method: "POST",
        data: date,
    });

// 验证码找回密码
export const updatePassByMobile = (date) =>
    axios({
        url: "/login/updatePassByMobile",
        method: "POST",
        data: date,
    });

// 手机号密码登录
export const loginPass = (date) =>
    axios({
        url: "/login/loginPass",
        method: "POST",
        data: date,
    });

// 获取协议
export const protocol = (date) =>
    axios({
        url: "/index/protocol",
        method: "POST",
        data: date,
    });
// 菜单分类
export const menuCate = (date) =>
    axios({
        url: "/area/menuCate",
        method: "POST",
        data: date,
    });

// 配置中心
export const infoConfig = (date) =>
    axios({
        url: "/index/infoConfig",
        method: "POST",
        data: date,
    });


// 友情链接
export const link = (date) =>
    axios({
        url: "/index/link",
        method: "POST",
        data: date,
    });





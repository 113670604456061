import { render, staticRenderFns } from "./FootBox.vue?vue&type=template&id=9cce5ccc&scoped=true"
import script from "./FootBox.vue?vue&type=script&lang=js"
export * from "./FootBox.vue?vue&type=script&lang=js"
import style0 from "./FootBox.vue?vue&type=style&index=0&id=9cce5ccc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9cce5ccc",
  null
  
)

export default component.exports
// 异步封装模块
import axios from "axios";
import { getSessionItem } from './storage'

const instance = axios.create({
  baseURL: "https://knowapi.hongyunkeji.top",
  timeout: 10000,
});
// 请求拦截器
instance.interceptors.request.use((request) => {
  // 添加请求头
  const token = getSessionItem('token')
  if (token) {
    // console.log(token,'tokentokentoken')
    request.headers['Authorization'] = token
    request.headers['Token'] = token
  }
  return request;
});

// 响应拦截器
instance.interceptors.response.use((response) => {
  // if () {
    
  // }
  return response.data;
});

function request({
  url = "https://knowapi.hongyunkeji.top", // 请求地址
  method = "get", // 请求方式
  headers = {}, // 请求头
  params = {}, // get参数
  data = {}, // post参数
  timeout = 10000, // 超时时间
  controller,
}) {
  // 获取取消请求信号对象
  const signal = controller ? controller.signal() : undefined;
  return instance({
    url,
    method,
    headers,
    params,
    data,
    timeout,
    signal,
  });
}

export default request;

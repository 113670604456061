// 卡片信息
import axios from "../utils/request";

// 立即联系
export const addContact = (date) =>
    axios({
        url: "/member/addContact",
        method: "POST",
        data:date
    });

import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'
import router from './router/index'
import Vuex from "vuex";
import { parseTime, formatDate,formatDate2,resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree, isSuperAdmin } from "@/utils/utils";

import store from "./store";

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter)
Vue.use(Vuex);


// 全局方法挂载
Vue.prototype.parseTime = parseTime
Vue.prototype.formatDate = formatDate
Vue.prototype.formatDate2 = formatDate2
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree
Vue.prototype.apiUrl = 'https://knowapi.hongyunkeji.top'
Vue.prototype.delText = '确定将选择数据删除？<br/>删除后不可恢复！';

Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}
Vue.prototype.$bus = new Vue;

Vue.prototype.getToken =sessionStorage.getItem("token")


Vue.prototype.getLogin = function () {
  sessionStorage.getItem("isLogin")
}

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

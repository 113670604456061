// 首页
import axios from "../utils/request";

// 轮播图
export const banner = (date) =>
    axios({
        url: "/index/banner",
        method: "POST",
    });
// 首页
export const index = (date) =>
    axios({
        url: "/index/index",
        method: "POST",
    });
// 专利、商标、版权咨询
export const lists = (date) =>
    axios({
        url: "/qualificationsApply/lists",
        method: "POST",
        data:date
    });
// 资讯详情接口
export const informationGetbyid = (date) =>
    axios({
        url: "/information_lists/getbyid",
        method: "POST",
        data:date
    });
// 首页-搜索
export const indexsearch = (date) =>
    axios({
        url: "/index/indexsearch",
        method: "POST",
        data:date
    });
// 首页滚动通知公告
export const indexNoticleList = (date) =>
    axios({
        url: "index/indexNoticleList",
        method: "POST",
        data:date
    });

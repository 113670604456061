// 个人中心
import axios from "../utils/request";


// 上传图片
export const uploadFile = (date) =>
    axios({
        url: "/public/uploadFile",
        method: "POST",
        data: date,
    });
// 识别身份证
export const idCardAuth = (date) =>
    axios({
        url: "/member/idCardAuth",
        method: "POST",
        data: date,
    });

// 识别营业执照
export const bussinessOcr = (date) =>
    axios({
        url: "/member/bussinessOcr",
        method: "POST",
        data: date,
    });


// 修改手机号码发送验证码
export const sendUpdateMobileSms = (date) =>
    axios({
        url: "/personal/sendUpdateMobileSms",
        method: "POST",
        data: date,
    });

// 修改手机号
export const updateMobile = (date) =>
    axios({
        url: "/personal/updateMobile",
        method: "POST",
        data: date,
    });


// 修改密码
export const updatePass = (date) =>
    axios({
        url: "/member/updatePass",
        method: "POST",
        data: date,
    });

// 实名认证
export const realName = (date) =>
    axios({
        url: "/member/doReal",
        method: "POST",
        data: date,
    });

// 个人信息
export const personalInfo = (date) =>
    axios({
        url: "/personal/info",
        method: "POST",
        data: date,
    });

// 修改基本信息
export const wxUpdateUserInfo = (date) =>
    axios({
        url: "/personal/wxUpdateUserInfo",
        method: "POST",
        data: date,
    });

// 更新头像
export const updateAvatar = (date) =>
    axios({
        url: "/personal/updateAvatar",
        method: "POST",
        data: date,
    });

// 专利中心数据
export const userPatentList = (date) =>
    axios({
        url: "/userPatent/lists",
        method: "POST",
        data: date,
    });
// 专利中心取消审核
export const cancelExamine = (date) =>
    axios({
        url: "/userPatent/cancelExamine",
        method: "POST",
        data: date,
    });
// 专利中心删除
export const delUserPatent = (date) =>
    axios({
        url: "/userPatent/del",
        method: "POST",
        data: date,
    });
// 产权融资
export const userPropertyrightList = (date) =>
    axios({
        url: "/userPropertyright/lists",
        method: "POST",
        data: date,
    });
// 产权融资取消审核
export const userPropertyright = (date) =>
    axios({
        url: "/userPropertyright/cancelExamine",
        method: "POST",
        data: date,
    });
// 产权融资删除
export const delUserPropertyright = (date) =>
    axios({
        url: "/userPropertyright/del",
        method: "POST",
        data: date,
    });
// 创新成果
export const userAchievementstList = (date) =>
    axios({
        url: "/userAchievements/lists",
        method: "POST",
        data: date,
    });
// 创新成果取消审核
export const userAchievements = (date) =>
    axios({
        url: "userAchievements/cancelExamine",
        method: "POST",
        data: date,
    });
// 创新成果删除
export const delUserAchievements = (date) =>
    axios({
        url: "/userAchievements/del",
        method: "POST",
        data: date,
    });
// 运营投资
export const userInvestmentList = (date) =>
    axios({
        url: "/userInvestment/lists",
        method: "POST",
        data: date,
    });
// 运营投资取消审核
export const userInvestment = (date) =>
    axios({
        url: "/userInvestment/cancelExamine",
        method: "POST",
        data: date,
    });
// 运营投资删除
export const delUserInvestment = (date) =>
    axios({
        url: "/userInvestment/del",
        method: "POST",
        data: date,
    });
// 查看记录
export const contactList = (date) =>
    axios({
        url: "/member/contactList",
        method: "POST",
        data: date,
    });
// 消息列表
export const messageList = (date) =>
    axios({
        url: "/message/messageList",
        method: "POST",
        data: date,
    });
// 全部阅读
export const readAll = (date) =>
    axios({
        url: "/message/readAll",
        method: "POST",
        data: date,
    });
// 单个消息
export const messageOne = (date) =>
    axios({
        url: "/message/messageOne",
        method: "POST",
        data: date,
    });


// 帮助中心
export const helpList = (date) =>
    axios({
        url: "/index/helpList",
        method: "POST",
        data: date,
    });


// 帮助详情
export const helpDetail = (date) =>
    axios({
        url: "/index/helpDetail",
        method: "POST",
        data: date,
    });


<template>
    <div class="body">
        <div class="carouselBox">
            <div class="carouselBoxTop">
                <el-carousel :interval="5000" arrow="always" height="500px">
                    <el-carousel-item v-for="(item,i) in imgList" :key="item.id">
                        <img :src='item.banner' width="100%" height="500px" alt="" srcset="" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="carouselBoxText">
                <div style="width:100%;height:100%;position:absolute;top:-70px;z-index: 400;">
                    <div class="textBox">
                        <div v-for="(item,i) in inforList" :key="i" class="textBoxOne">
                            <div>{{item.num}}</div>
                            <div class="textMiniOne">{{item.text}}</div>
                        </div>
                    </div>
                    <div class="noticeBox">
                        <div class="noticeBoxTitle">
                            <div class="title">
                                <img src="../assets/ellipse.png" width="56px" height="56px" alt="" srcset="">
                                <div style="position: absolute;left: 24px;bottom:-5px;">
                                    通知公告
                                </div>
                            </div>
                            <div class="miniTitleBox" @click="jumpTo()">更多公告 <div class="miniTitleIcon"><i
                                        class="el-icon-arrow-right"></i></div>
                            </div>
                        </div>
                        <div class="noticeBoxMiniBox">
                            <vue-seamless-scroll :data="noticeList" :class-option="classOption" class="warp">
                                <div style="display: flex;">
                                    <div v-for="(item,i) in noticeList" :key="i" class="miniboxOne"
                                        @click="openMsg(item)">
                                        <div class="timeBox">
                                            <div class="timeText">{{item.moeth}}</div>
                                            <div class="yearTxte">{{item.yare}}</div>
                                        </div>
                                        <div style="width:68%;">
                                            <div class="miniTitleTxt">{{item.notice_type_name}}</div>
                                            <div class="titleText ellipsis">{{item.name}}</div>
                                        </div>
                                    </div>
                                </div>
                            </vue-seamless-scroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="innovate" v-for="(orderDate,i) in dateList" :key="i">
            <div class="innovatebox1">
                <div class="innovateTitle">
                    <div class="innovateTitleOne">
                        <img src="../assets/ellipse.png" width="56px" height="56px"
                            style="position: absolute;left:-24px;top: -10px;z-index: 0;" alt="" srcset="">
                        {{orderDate.bigTitle}}

                    </div>
                    <div class="innovateTitleMiniOne">{{orderDate.miniText}}</div>
                    <div class="applyBox" v-if="orderDate.bigTitle=='专利服务'" @click="openApply()">
                        <div class="applyBoxOne">专利申请</div>
                        <div class="applyBoxOne">商标申请</div>
                        <div class="applyBoxOne">相关政策</div>
                    </div>
                </div>
                <div class="innovateBox">
                    <div v-for="(item,i) in orderDate.resultList" :key="i" class="innovateOneBox">
                        <CardBox :resultDate="item" :key="item.id" :title="orderDate.bigTitle" />
                    </div>
                </div>
                <div class="miniTitle" @click="openMore(orderDate.bigTitle)">
                    {{orderDate.rigthTxte}}
                    <div class="miniTitleIcon"><i class="el-icon-arrow-right"></i></div>
                </div>

            </div>

        </div>
        <div class="realInfoBox">
            <div class="realInfoBoxTitle">
                <div class="realInfoBoxTitleLeft">
                    <img src="../assets/ellipse.png" width="56px" height="56px"
                        style="position: absolute;left: 500px;top: -10px;" alt="" srcset="">
                    资讯
                </div>
                <div class="realInfoBoxTitleLeftBtn">
                    <div v-for="(item,i) in informationList" :key="i" @click="changeServiceId(item)"
                        :class="serviceId==item.value?'isbtnOne':''" class="btnOne">{{item.name}}</div>
                </div>

            </div>
            <div class="realInfoContent">
                <div class="realInfoContentLeft" @click="getDetails(serviceList[0].id)">
                    <img :src="serviceList.length>0?serviceList[0].cover:''" width="100%" height="402px"
                        style="border-radius:16px" alt="" srcset="">
                    <div class="realInfoContentLeftText">
                        <div class="realInfoTextleft">
                            {{serviceList.length>0?serviceList[0].name:''}}
                        </div>
                        <div class="realInfoTextRight">
                            {{serviceList.length>0?formatDate(serviceList[0].create_time,1):''}}
                        </div>
                    </div>
                </div>
                <div class="realInfoContentTagBox">
                    <div v-for="(items,index) in serviceList" :key="index" class="realInfoContentTag"
                        @click="getDetails(items.id)">
                        <div class="realInfoContentTagLeft">
                            <div class="ellipsis">{{items.name}}</div>
                            <div class="realInfoContentTagLeftMini">
                                {{items.desc}}
                            </div>
                        </div>
                        <div class="realInfoContentTagRight">
                            <div>
                                {{formatDate2(items.create_time,2)}}
                            </div>
                            <div class="realInfoContentTagRightIcon">
                                <i class="el-icon-right"></i>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="miniTitle" @click="openMore('资讯')">更多资讯 <div class="miniTitleIcon"><i
                        class="el-icon-arrow-right"></i></div>
            </div>

        </div>
    </div>
</template>
<script>
    import vueSeamlessScroll from 'vue-seamless-scroll'
    import CardBox from '../components/CardBox.vue';
    import {
        banner,
        index,
        informationGetbyid,
        indexNoticleList
    } from '../api/homeApi.js'
    export default {
        name: 'HomePage',
        components: {
            CardBox,
            vueSeamlessScroll
        },
        data() {
            return {
                classOption: {
                    direction: 2,
                },
                imgList: [],
                inforList: [
                    {
                        num: 88888,
                        text: '注册用户量'
                    },
                    {
                        num: 88888,
                        text: '解决需求金额（亿元）'
                    }, {
                        num: 88888,
                        text: '落地项目'
                    }, {
                        num: 88888,
                        text: '服务企业'
                    },
                ],
                noticeList: [],
                dateList: [
                    {
                        bigTitle: '知识产权创新成果',
                        miniText: '多场景的成果转化解决方案与创新服务',
                        rigthTxte: '更多创新成果',
                        resultList: []
                    },
                    {
                        bigTitle: '知识产权融资',
                        miniText: '他们《天赋河套知识产权服务平台》的帮助下成功融资',
                        rigthTxte: '更多融资信息',
                        resultList: []
                    },
                    {
                        bigTitle: '专利服务',
                        miniText: '为中小型企业及个人提供在线的信息咨询服务',
                        rigthTxte: '更多专利服务',
                        resultList: []
                    },
                    {
                        bigTitle: '资深顾问团队',
                        miniText: '为您提供强有力的技术指导',
                        rigthTxte: '更多专家信息',
                        resultList: []
                    }
                ],
                informationList: [],
                serviceId: 0,
                serviceList: [],
            }

        },
        created() {
            // this.getBanner();
            this.getDateBox();
            this.getNoticleList();
            clearInterval();

        },
        methods: {
            // 获取轮播图
            getBanner() {
                banner().then(res => {
                    if (res.code == 200) {
                        this.imgList = res.data;
                    } else {
                        this.msgError(res.msg)
                    }
                })
            },
            // 首页数据
            getDateBox() {
                this.dateList[0].resultList = [];
                this.dateList[1].resultList = [];
                this.dateList[2].resultList = [];
                this.dateList[3].resultList = [];
                index().then(res => {
                    if (res.code == 200) {
                        this.imgList = res.data.banner;
                        this.dateList[0].resultList = res.data.userAchievements;
                        this.dateList[1].resultList = res.data.uerPropertyrightList;
                        this.dateList[2].resultList = res.data.userPatentList;
                        this.dateList[3].resultList = res.data.expertList;
                        for (let i = 0; i < this.dateList.length; i++) {
                            for (let j = 0; j < this.dateList[i].resultList.length; j++) {
                                var img = [];
                                img = this.dateList[i].resultList[j].imgs ? this.dateList[i].resultList[j].imgs.split(',') : [];
                                this.dateList[i].resultList[j].oneImg = img.length > 0 ? img[0] : ''

                            }

                        }
                        // this.noticeList = res.data.notice;
                        // this.noticeList.forEach(e => {
                        //     e.yare = this.formatDate2(e.create_time, 1);
                        //     e.moeth = this.formatDate2(e.create_time, 2);
                        // });
                        // if (this.noticeList.length==3) {
                        //     this.noticeList.push(this.noticeList[0],this.noticeList[1],this.noticeList[2])
                        // }else if (this.noticeList.length==5) {
                        //     this.noticeList.push(this.noticeList[0],this.noticeList[1],this.noticeList[2],this.noticeList[3])
                        // }

                        this.inforList[0].num = res.data.allUserCount;
                        this.inforList[1].num = res.data.resolveDemandMoney;
                        this.inforList[2].num = res.data.landingProject;
                        this.inforList[3].num = res.data.serviceEnterprise;
                        this.informationList = res.data.consultMenu;
                        this.serviceId = this.informationList[0].value;
                        for (let i = 0; i < this.informationList.length; i++) {
                            var value = this.informationList[i].value;
                            this.informationList[i].list = res.data.informationList[value];
                            // console.log(value, 'value')
                            // console.log(res.data.informationList[value])

                        }

                        if (this.informationList[0].list.length > 3) {
                            this.serviceList = this.informationList[0].list.slice(0, 3)
                        } else {
                            this.serviceList = this.informationList[0].list;
                        }
                    } else {
                        this.msgError(res.msg)
                    }
                })
            },
            // 获取公告列表
            getNoticleList() {
                indexNoticleList().then(res => {
                    if (res.code == 200) {
                        this.noticeList = res.data.data;
                        this.noticeList.forEach(e => {
                            e.yare = this.formatDate2(e.create_time, 1);
                            e.moeth = this.formatDate2(e.create_time, 2);
                        });
                        if (this.noticeList.length == 3) {
                            this.noticeList.push(this.noticeList[0], this.noticeList[1], this.noticeList[2])
                        } else if (this.noticeList.length == 5) {
                            this.noticeList.push(this.noticeList[0], this.noticeList[1], this.noticeList[2], this.noticeList[3])
                        }
                    } else {

                    }
                })
            },
            // 获取资讯详情
            getDetails(id) {
                this.$router.push({
                    path: '/NoticeMsg',
                    query: {
                        val: '咨询',
                        id: id
                    }
                })


            },

            // 切换咨询类型服务
            changeServiceId(item) {
                this.serviceId = item.value;
                if (item.list.length > 3) {
                    this.serviceList = item.listslice(0, 3)
                } else {
                    this.serviceList = item.list;
                }
            },
            // 跳转公告详情
            openMsg(item) {
                this.$router.push({
                    path: '/NoticeMsg',
                    query: {
                        id: item.id
                    }
                })
            },
            // 跳转 专利、商标、版权详情
            openApply() {
                this.$router.push({
                    path: '/QualificaApply',
                    query: {}
                })
            },
            // 跳转通知公告更多
            jumpTo() {
                this.$router.push({
                    path: '/Notice',
                    query: {}
                })
            },
            openMore(val) {
                this.$router.push({
                    path: '/ScreenOut',
                    query: {
                        type: '首页',
                        val: val,
                        typeId: this.serviceId
                    },
                })

            }

        }
    }
</script>
<style scoped>
    .body {
        width: 100%;
        background: #F5F5F5;
    }

    .carouselBox {
        width: 100%;
        height: 880px;
        position: relative;
        background-color: white;
    }

    .carouselBoxTop {
        width: 100%;
        height: 500px;

    }

    .carouselBoxText {
        width: 100%;
        height: 380px;
        box-sizing: border-box;
        background: linear-gradient(to bottom, #FFFFFF, #E2FBF2);
        position: relative;
    }

    .textBox {
        width: 1120px;
        height: 136px;
        margin: 0 auto;
        background: #00A166;
        border-radius: 12px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        cursor: pointer;
        cursor: hand;
    }

    .textBoxOne {
        width: 354px;
        height: 77px;
        font-weight: bold;
        font-size: 40px;
        color: #FFFFFF;
        line-height: 47px;
        text-align: center;
        border-right: 2px solid white;
    }

    .textBoxOne:nth-child(4) {
        border-right: none;
    }

    .textMiniOne {
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 19px;
        text-align: center;
    }

    .noticeBox {
        width: 1120px;
        margin: 50px auto 0 auto;

    }

    .noticeBoxTitle {
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }

    .title {
        width: 500px;
        height: 56px;
        font-weight: 500;
        font-size: 36px;
        color: #333333;
        position: relative;
    }

    .miniTitleBox {
        font-weight: 400;
        font-size: 16px;
        color: #00A166;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        cursor: hand;
    }

    .miniTitle {
        width: 100%;
        height: 120px;
        font-weight: 400;
        font-size: 16px;
        color: #00A166;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        cursor: hand;
    }

    .miniTitleIcon {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #00A166;
        text-align: center;
        line-height: 16px;
        color: white;
        margin-left: 10px;
        font-size: 10px;
    }

    .warp {
        width: 100%;
        height: 130px;
        overflow: hidden;
    }

    .noticeBoxMiniBox {
        width: 100%;
        height: 130px;
        margin-top: 48px;
        cursor: pointer;
        cursor: hand;
    }

    .noticeBoxMiniBoxText {
        width: 100%;
        float: left;
        position: absolute;
        top: 0px;
        left: 0;
    }

    .miniboxOne {
        width: 450px;
        height: 108px;
        /* padding-left: 2%; */
        box-sizing: border-box;
        border-left: 3px solid #FFFFFF;
        display: flex;
        /* justify-content: space-between; */
    }

    .timeBox {
        width: 28%;
        height: 100%;
        padding-top: 10px;
        padding-right: 20px;
        box-sizing: border-box;
        text-align: right;

    }

    .timeText {
        width: 100%;
        font-weight: 500;
        font-size: 34px;
        color: #333333;
        line-height: 40px;

    }

    .yearTxte {
        font-weight: 400;
        font-size: 20px;
        color: #666666;
        line-height: 23px;
    }

    .miniTitleTxt {
        max-width: 290px;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
        line-height: 19px;
        text-align: left;
    }

    .titleText {
        width: 100%;
        height: 72px;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 36px;
        text-align: left;
        text-wrap: wrap;
        margin-top: 12px;
    }

    .innovate {
        width: 100%;
        padding-top: 48px;
        box-sizing: border-box;
    }

    .innovatebox1 {
        width: 1120px;
        margin: 0 auto;
    }

    .innovate:nth-child(2) {
        background-image: url('../assets/bgimg1.png');
        background-size: 100% 100%;
    }

    .innovate:nth-child(3) {
        background-color: white;
    }

    .innovate:nth-child(4) {
        background-image: url('../assets/bgimg3.png');
        background-size: 100% 100%;
        /* 让背景图片宽度铺满，高度自动 */
    }

    .innovate:nth-child(5) {
        background-image: url('../assets/bgimg4.png');
        background-size: 100% 100%;
        /* 让背景图片宽度铺满，高度自动 */
        background-repeat: repeat-y;
    }

    .innovateTitle {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .innovateTitleOne {
        height: 56px;
        font-weight: 500;
        font-size: 36px;
        color: #333333;
        line-height: 42px;
        text-align: center;
        position: relative;
    }

    .innovateTitleMiniOne {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        line-height: 19px;
        text-align: center;
    }

    /* .innovateBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        flex-wrap: wrap;
    }

    .innovateOneBox {
        width: 343px;
        background: #FFFFFF;
        border-radius: 12px;
        margin-bottom: 24px;
        padding: 16px;
        box-sizing: border-box;
    }

    .innovateBoxText {
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        line-height: 23px;
        padding: 12px 0;
    }

    .tagBox {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }

    .spanbox {
        background: #F4F4F4;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        color: #999999;
        line-height: 14px;
        padding: 10px;
        margin-right: 4px;
        margin-bottom: 4px;
    }

    .gainInfo {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 16px;
    }

    .gainInfoTetx {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 16px;
        margin-left: 8px;
    }

    .gainPhone {
        font-weight: 400;
        font-size: 14px;
        color: #00A166;
        line-height: 16px;
        display: flex;
        justify-content: space-between;
    } */

    .applyBox {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        color: #00A166;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        cursor: hand;
        margin-top: 24px;
    }

    .applyBoxOne {
        width: 128px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #58CDA2;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
        color: #00A166;
        text-align: center;
        line-height: 44px;
    }

    .applyBoxOne:nth-child(2) {
        margin: 0 50px;
    }

    .applyBoxOne:hover {
        width: 128px;
        height: 44px;
        background: #00A166;
        border-radius: 4px;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 16px;
        color: #FFFFFF;
        text-align: center;
        line-height: 44px;
    }

    .character {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .characterName {
        font-weight: 500;
        font-size: 32px;
        color: #333333;
        line-height: 38px;
        margin-top: 10px;
        margin-bottom: 6px;
    }

    .characterText {
        font-weight: 400;
        font-size: 16px;
        color: #333333;
        line-height: 19px;
    }

    .realInfoBox {
        width: 100%;
        background-color: white;
        padding: 48px 0;
        box-sizing: border-box;
    }

    .realInfoBoxTitle {
        width: 1120px;
        margin: 0 auto;

    }

    .realInfoBoxTitleLeft {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Source Han Sans SC, Source Han Sans SC;
        font-weight: 500;
        font-size: 36px;
        color: #333333;
        line-height: 42px;
        position: relative;
    }

    .realInfoBoxTitleLeftBtn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        cursor: hand;
        margin-top: 24px;

    }

    .btnOne {
        min-width: 128px;
        height: 44px;
        background: white;
        border-radius: 4px;
        text-align: center;
        line-height: 44px;
        font-weight: 500;
        font-size: 16px;
        color: #999999;
        margin-right: 10px;
        border-radius: 4px;
        border: 1px solid #DADBDF;
    }


    .isbtnOne,
    .btnOne:hover {
        background: #00A166;
        text-align: center;
        color: #FFFFFF !important;
    }

    .realInfoContent {
        width: 1120px;
        margin: 0 auto;
        justify-content: space-between;
        /* align-items: center; */
        margin-top: 60px;
        display: flex;
        cursor: pointer;
        cursor: hand;
    }

    .realInfoContentLeft {
        width: 49%;
        position: relative;
    }

    .realInfoContentLeftText {
        width: 100%;
        height: 60px;
        background: rgba(51, 51, 51, 0.8);
        border-radius: 0px 0px 16px 16px;
        position: absolute;
        top: 342px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        box-sizing: border-box;
        font-weight: 400;
        font-size: 16px;
        color: #FFFFFF;
    }

    .realInfoTextleft {
        max-width: 444px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .realInfoTextRight {
        font-weight: 400;
        font-size: 14px;
    }

    .realInfoContentTagBox {
        width: 48%;

    }

    .realInfoContentTag {
        width: 100%;
        height: 142px;
        background: white;
        padding: 10px 28px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333333;
    }

    .realInfoContentTag:hover,
    .realInfoContentTag:hover div {
        background: #00A166;
        color: white !important;
    }

    .realInfoContentTag:hover .realInfoContentTagRightIcon {
        border-left: 2px solid white;
    }

    .realInfoContentTagLeft {
        width: 86%;
        font-weight: 500;
        font-size: 20px;
    }

    .realInfoContentTagLeftMini {
        height: 60px;
        font-weight: 400;
        font-size: 15px;
        color: #666666;
        line-height: 30px;
        margin-top: 12px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .realInfoContentTagRight {
        width: 60px;
        height: 102px;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        line-height: 16px;
    }

    .realInfoContentTagRightIcon {
        width: 100%;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border-left: 1px solid #999999;
        opacity: 0.9;
        margin-top: 24px;
    }
</style>
// vuex 模块化封装
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import login from "./modules/login";

export default new Vuex.Store({
  modules: {
    login,
  },
});

// 用户数据模块

const state = () => {
  return {
    isLogin: false,
    token: ''
  }
}

const mutations = {
  ISLOGIN(state, isLogin) {
    state.isLogin = isLogin
  },
  TOKEN(state, token) {
    state.token = token;
  }
}

const actions = {
  changeIsloginAction({ state, commit }) {
    commit("ISLOGIN", state);

  }
};

const getters = {}

// 导出
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};